import { render, staticRenderFns } from "./DonutBase.vue?vue&type=template&id=bdb09066&scoped=true"
import script from "./DonutBase.vue?vue&type=script&lang=js"
export * from "./DonutBase.vue?vue&type=script&lang=js"
import style0 from "./DonutBase.vue?vue&type=style&index=0&id=bdb09066&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdb09066",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VSkeletonLoader})
