export default [
  {
    id: 1,
    name: 'Dr. Peter Romanow',
    position: 'vorstandvor',
    image: require('@/assets/gfx/management/romanow.jpg'),
  },
  {
    id: 2,
    name: 'Joachim Bittner',
    position: 'vorstand',
    image: require('@/assets/gfx/management/bittner.jpg'),
  },
  {
    id: 3,
    name: 'Dr. Rolf Schirmacher',
    position: 'vorstand',
    image: require('@/assets/gfx/management/schirmacher.jpg'),
  },
  {
    id: 4,
    name: 'Prof. Dr.-Ing. Gerhard Müller',
    position: 'aufsichtsratvor',
    image: require('@/assets/gfx/management/mueller.jpg'),
  },
  {
    id: 5,
    name: 'Wolfgang Schnell',
    position: 'aufsichtsratstell',
    image: require('@/assets/gfx/management/schnell.jpg'),
  },
  {
    id: 6,
    name: 'Dr.-Ing. Rudolf K. Jürcke, M. Sc.',
    position: 'aufsichtsrat',
    image: require('@/assets/gfx/management/juercke.jpg'),
  },
  {
    id: 8,
    name: 'Stefan Schmiedel',
    position: 'aufsichtsrat',
    image: require('@/assets/gfx/management/schmiedel.jpg'),
  },
  {
    id: 9,
    name: 'Michaela Stenert',
    position: 'aufsichtsrat',
    image: require('@/assets/gfx/management/stenert.jpg'),
  },
]
