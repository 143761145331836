<template>
  <div class="two-factor-auth--wrap">
    <v-alert
      color="secondary"
      dark
      icon="mdi-information"
      class="d-inline-flex mb-8"
      outlined
      max-width="90%"
    >
      {{ $t('login.twoFactorInfo') }}
    </v-alert>

    <div class="mb-6 d-flex align-center">
      <strong>
        {{ $t('login.twoFactorStatus') }}
      </strong>
      <v-chip :color="user.twoFactorAuthenticationEnabled ? 'success' : 'amber darken-3'" class="ml-2" dark>
        {{ $t(user.twoFactorAuthenticationEnabled ? 'common.enabled' : 'common.disabled') }}
      </v-chip>
    </div>

    <div v-if="user.twoFactorAuthenticationEnabled">
      <h2 class="mb-4 text-h6">
        {{ $t('login.disableTwoFactor') }}
      </h2>
      <v-btn depressed @click="showDisableDialog = true">
        <v-icon left color="primary">
          mdi-lock-off
        </v-icon>
        {{ $t('common.disable') }}
      </v-btn>
    </div>

    <div v-else>
      <h2 class="mb-4 text-h6">
        {{ $t('login.enableTwoFactor') }}
      </h2>
      <v-btn color="primary" depressed :loading="isLoading" @click="activate2fa">
        <v-icon left>
          mdi-check
        </v-icon>
        {{ $t('common.enable') }}
      </v-btn>
    </div>

    <two-factor-dialog
      :is-visible="showConfirmDialog"
      :secret="secret"
      :qr-code="qrCode"
      @close="showConfirmDialog = false"
      @confirmed="onConfirm"
    />

    <v-dialog
      :value="showDisableDialog"
      width="500"
      max-width="90%"
      @input="visibility => showDisableDialog = visibility"
    >
      <form @submit.prevent="disable2fa">
        <v-card>
          <v-card-title>{{ $t('login.twoFactorModalHead') }}</v-card-title>
          <v-card-subtitle>{{ $t('login.twoFactorModalInfo') }}</v-card-subtitle>
          <v-card-text>
            <v-text-field
              v-model="code"
              :label="$t('login.twoFactorCode')"
              outlined
              hide-details
              autofocus
              required
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn depressed @click="showDisableDialog = false">
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn depressed type="submit">
              <v-icon left color="primary">
                mdi-lock-off
              </v-icon>
              {{ $t('common.disable') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import AccountApi from '@/api/Account'
import TwoFactorAuthApi from '@/api/TwoFactorAuth'
import TwoFactorDialog from './TwoFactorDialog'

export default {
  name: 'two-factor-auth',

  components: {
    TwoFactorDialog,
  },

  data () {
    return {
      isLoading: false,
      showConfirmDialog: false,
      showDisableDialog: false,
      secret: '',
      qrCode: '',
      code: '',
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    }
  },

  methods: {
    /**
     * Starts the 2fa-activation for the current user. After that, we get a
     * secret and a qr-code to show to the user within a dialog where he has to
     * confirm it.
     *
     * @returns {void}
     */
    async activate2fa () {
      this.isLoading = true

      const res = await TwoFactorAuthApi.enable()

      if (res.ok) {
        const { secret, qrCodeDataUri } = await res.json()
        this.secret = secret
        this.qrCode = qrCodeDataUri
        this.showConfirmDialog = true
      } else {
        this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
      }

      this.isLoading = false
    },

    /**
     * Tries to disable 2fa for the current user with the entered security-code.
     *
     * @returns {void}
     */
    async disable2fa () {
      const res = await TwoFactorAuthApi.disable(this.code)
      this.showDisableDialog = false

      if (res.ok) {
        this.reloadUser()
        this.$store.commit('setSnackbar', { text: this.$t('login.twoFactorDisabled') })
        return
      }

      this.$store.commit('setSnackbar', { text: this.$t('login.twoFactorNotDisabled'), color: 'error' })
    },

    /**
     * Reloads the current user to apply latest changes.
     *
     * @returns {void}
     */
    async reloadUser () {
      const userRes = await AccountApi.me()
      userRes.ok && this.$store.commit('setUser', await userRes.json())
    },

    /**
     * Resets the internal state, reloads the current user after the
     * confirmation.
     *
     * @returns {void}
     */
    onConfirm () {
      this.code = ''
      this.secret = ''
      this.qrCode = ''

      this.reloadUser()
    },
  }
}
</script>
