import { render, staticRenderFns } from "./CompanyDistributionPolar.vue?vue&type=template&id=19861108&scoped=true"
import script from "./CompanyDistributionPolar.vue?vue&type=script&lang=js"
export * from "./CompanyDistributionPolar.vue?vue&type=script&lang=js"
import style0 from "./CompanyDistributionPolar.vue?vue&type=style&index=0&id=19861108&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19861108",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VCardActions,VCardTitle,VDivider,VSkeletonLoader,VSpacer,VSwitch})
